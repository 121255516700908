body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.coursecard {
  transition: all 0.2s;
}
.coursecard:hover {
  transform: scale(1.05);
}
header {
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  left: 0px;
}
.featureicon {
  display: block !important;
  margin: 10px auto !important;
  
  color: #35068d;
  
}
#panel2-header {
  height: 40px !important;
}
.aliginicon,.featureicon {
  color: #35068d;
  font-size: 40px !important;
}
i svg {
  font-size: 20px;
}
.modebgcolor {
  background:#fef4dc;
  border:solid 10px #fff;
min-height: 250px;
}
.h_social_media {
  width: 100%;
  height: 40px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}
.h_social_media i{
padding: 0px 10px;
}
.aliginicon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  line-height: 60px;

}
.iconsize {
  font-size: 20px !important;
}

header input[type=text]{
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
}
/* .menunav{
width: 100%;
height: 55px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
} */
/* .navitems {
  width: 70%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
 */
/* .bg-body-tertiary .logo img {
  width: 200px;
  height: 100px;
  margin-top: 10px;
  
} */

.socialmedia {
  width: 30%;
  height: 55px;
  }
/* .menunav ul {
  padding: 0px;
  margin: 0px;
} */
/* .bg-body-tertiary ul li {
  color:rgb(53, 6, 141);
  font-weight: bold;
  list-style: none;
  cursor: pointer;
  font-size: 13px;
} */
/* .bg-body-tertiary  ul:last-child {
  background-color: #35068d;
  padding: 10px 30px;
  box-sizing: border-box;
  color: #fff;

} */
/* .bg-body-tertiary  ul:last-child li {
  color: #fff;
} */
.mainicons {
  font-size: 60px;
  color: #f00;
  animation: iconsanimation 1s infinite alternate !important;
  transition: all 1s;
}
.mainicons:hover {
  transform: scale(1.4);
}
.mt {
  margin:30px auto;
}
@keyframes iconsanimation {
  from{
    transform: scale(1);
  }
  to{

    transform: scale(1.2);
  }
}
.banner {
  height: 500px;
/*  background-image: url('https://whf.org.nz/wp-content/uploads/2021/03/TWN_Intranet-banner-Launch-HVDHB-600px-x-300px.png'); */
background-image: url('https://images.pexels.com/photos/2049422/pexels-photo-2049422.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
 background-size: cover;
}

.bodycontainer {
  min-height: 600px;
  width: 100%;
  display: flex;
}
.bodycontainer aside {
  width: 25%;
  min-height: 600px;
/*   background-color: rgb(40, 138, 138); */
}
.bodycontainer article {
  width: 80%;
  min-height: 600px;
  margin: 40px auto;
  
}
.footer {
  width: 100%;
  background-color:#35068d;
  text-align: center;
  line-height: 40px;
  color: white;
}

h2 {
  color:#35068d !important;
  padding: 5px;
  margin: 50px 0px;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
}
.bodycontainer article p {
  margin: 20px;

  padding: 0px;
}
article {
  text-align: center;
}
.prefooter {
  min-height: 300px;
  background-color:rgb(227, 227, 227);
}
.contentcontainer {
  display: flex;

}
.contentcontainer img {
width: 50%;
}
.paracontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width:780px){
/*   .menunav{
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;
    
  }
  .menunav .navitems{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;
    
  } */
/*   .menunav .navitems .logo img {
    height: 200px;
    flex-wrap: wrap;
  } */
/*   .menunav .navitems li {
    color:rgb(53, 6, 141);
    font-weight: bold;
    list-style: none;
    cursor: pointer;
    background-color:rgb(227, 227, 227);
    margin:2px auto;
    width: 100%;
    text-align: center;
    padding: 20px auto;
    line-height: 40px;
  }
  .menunav .navitems:last-child {
    background-color: #35068d;
    padding: 10px 30px;
    box-sizing: border-box;
    color: #fff;
  } */
  .bg-body-tertiary div:last-child() {
    background-color: lightblue !important;
  }
  .primarybg {
    background-color: lightskyblue !important;
  }
  .bodycontainer article {
    width: 100%;
    min-height: 600px;
    margin: 40px auto;
    
  }
 
  .bodycontainer article h2 {
    color:#35068d !important;
    padding: 5px;
    margin: 10px;
  }
  .bodycontainer article p {
    margin: 10px;
    padding: 10px;
  }
  .mt {
    margin:30px auto;
    
  }
  header {
    display: block;
  }
  .contentcontainer {
    display: flex;
    flex-direction: column;
  
  }
  .contentcontainer img {
  width: 100%;
  }
  .courses img {
    width: 100%;
  }

}